<template>
	<!-- eslint-disable -->
	<v-container id="dashboard" fluid class="munjz-bg-grey "  tag="section"  v-if="hasPermission('view-dashboard')">
		<v-card flat class="px-0 transparent ">
			<v-row class=" ma-0" dense>
				<v-col class="px-0 py-2"  cols="12" sm="12">
						<h1 >Dashboard</h1>
				</v-col>
				<!-- Filter by date range -->
				<v-col class="pa-0 pe-sm-2"  sm="5" md="3" >
					<v-menu
        ref="dateRangeMenue"
        v-model="dateRangeMenue"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
	
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            label="Pick a date range"
            readonly
            range
			class="white"
            dense
            outlined
            hide-details
            v-bind="attrs"
            v-on="on"
          ><template v-slot:prepend-inner><v-icon>mdi-calendar</v-icon></template> </v-text-field>
        </template>
        <v-date-picker
        color="munjz-accent"
		class=" ma-0"
			
          v-model="dates"
          range
          dense
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="dateFilterState = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dateRangeMenue.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
				</v-col>
       <v-col
        cols="12"
        sm="5"
        md="2"
		class="mt-5 mt-sm-0 pa-0 ps-sm-2  mx-md-5"
      >
        <v-select
          :items="branches"
          label="Select Branch"
          outlined
		  	class="white"
          dense
          hide-details
        ></v-select>
      </v-col>

            </v-row>
            <!-- Filters End -->
            <v-row class=" transparent " >
				<v-col cols="12" sm="7" md="8"  >
						<v-card
						flat 
						class="ma-0 rounded-lg" 
						>
						<v-card-title>Total Orders</v-card-title>
						<v-card-title><h2>400</h2></v-card-title>
                            <apexchart type="bar" height="350" :options="total_profit_chartOptions" :series="total_profit_chart"></apexchart>
						</v-card>
				
				</v-col>

				<v-col cols="12" sm="5" md="4" class="  ">
					<v-row class="" >
						<v-col cols="12" class="">
						<v-card
                            
                            flat
                            class="profit_card pa-5 ma-0 rounded-lg"
							color="#e1f8e6"
						>
							<v-card-title>
								<h4 class="green--text">Total Profit</h4>
							</v-card-title>
							<v-card-text>
									<h2 class="mt-2 green--text">{{ format_total_profit }}</h2>
							</v-card-text>
                            
						</v-card>
						</v-col>
				<!-- My Wallet & Credits Card -->
					<v-col  class=" ma-0 ">
							<v-card flat class=" ma-0 rounded-lg">
							<v-row class="pa-0 ma-0" dense>
							<v-col cols="6" class="">
						<v-card-title>
							<h4 >My wallet</h4>
							</v-card-title>
						<v-card-text>
							<h2 class="mt-2 blue--text">{{ format_total_profit }}</h2>
							</v-card-text>

						</v-col>
					
						<v-col cols="6" class=" ">
						<v-card-title>
							<h4 >My Credit</h4>
							</v-card-title>
							<v-card-text>
							<h2 class="mt-2 ">30,000 SR</h2>
							</v-card-text>
						
						</v-col>
							<v-col > <v-divider class="grey "></v-divider>
								</v-col>
							
								</v-row>	
					
					
						<v-col cols="12"  class=" ">
						<v-card-title>
							<h4 >My Balance</h4>
							</v-card-title>

							<apexchart type="donut" height="300" :options="my_wallet_chart.chartOptions" :series="my_wallet_chart.series"></apexchart>
						</v-col>
					</v-card>

						</v-col>
						
						</v-row>	
				</v-col>
		
                </v-row>
            
				<v-col cols="12" sm="6" lg="3" class=" ">
			
						<v-card
							class="mx-auto d-flex flex-column justify-center align-center px-0 pt-6"
							id="cardd"
							outlined
							color="#00E396"
							max-height="80"
						>
							<div></div>
							<v-card-title>
								<v-icon color="white" class="ml-n4 mt-1 mr-1">
									mdi-checkbox-multiple-marked-circle-outline
								</v-icon>
								<p class="lab mt-4">Complete orders</p>
							</v-card-title>
							<v-card-text>
								<center>
									<p class="mt-n4">{{ completed_orders }}</p>
								</center>
							</v-card-text>
						</v-card>
		
				</v-col>


				<v-col cols="12" sm="6" lg="3" class=" ">
				
						<v-card
					
							class="mx-auto d-flex flex-column justify-center align-center px-0 pt-6"
							id="cardd"
							outlined
							color="primary"
							max-height="80"
						>
							<div></div>
							<v-card-title class="d-flex justify-space-between">
								<v-icon small color="white" class="mr-4">
									mdi-cash-multiple
								</v-icon>
								<p class="lab mt-4">Total sales</p>
							</v-card-title>
							<v-card-text>
								<center>
									<p class="">{{ total_sales }}</p>
								</center>
							</v-card-text>
						</v-card>
	
				</v-col>

				<v-col cols="11" md="5" sm="3" class="d-flex flex-row">
					<span
						><v-label><h1 style="color:grey">Your Balance</h1> </v-label>
						<div class="d-flex flex-row align-center mt-12 ml-4">
							<v-btn x-small fab color="#00E396"></v-btn>
							<span
								><h4 class="mr-3 mb-2">Available</h4>
								<label> {{ Available }} SR </label></span
							>
						</div>
						<div class="d-flex flex-row align-center mt-8 ml-4">
							<v-btn x-small fab color="#D50000"></v-btn>
							<span
								><h4 class="mr-3 mb-2">Spent</h4>
								<label>{{ total_spent }} SR </label></span
							>
						</div>
					</span>
					<span class="d-flex flex-column align-center mt-n4 ml-">
						<v-progress-circular
							rotate="-90"
							:size="160"
							:width="35"
							:value="
								Math.ceil((spent.credit_spent * 100) / spent.credit_limit, 2)
							"
							color="primary"
							class="mt-16"
						>
							{{
								Math.ceil((spent.credit_spent * 100) / spent.credit_limit, 2)
							}}
							%
						</v-progress-circular>
						<b><label style="color:#2196F3">Balance now</label></b>
					</span>
				</v-col>

				<v-col cols="11" sm="3" md="3" class="ml-n16">
					<v-label
						><h1 style="color:grey">
							Top service (<small>Completed Orders</small>)
						</h1></v-label
					>
					<div class="pt-3" v-for="(item, i) in topServ" :key="i" v-if="i < 3">
						{{ topServ[i].name }}
						<v-progress-linear
							v-model="topServ[i].services_count"
							height="25"
							striped
							color="#00E396"
						>
							<strong>{{
								Math.ceil(parseInt(topServ[i].services_count))
							}}</strong>
						</v-progress-linear>
					</div>
				</v-col>
				<v-col cols="11" sm="1"></v-col>
				<v-col cols="11" sm="3">
					<v-label
						><h1 style="color:grey">
							Top Branch (<small>Completed Orders</small>)
						</h1></v-label
					>

					<div class="pt-3" v-for="(item, m) in detail" :key="m" v-if="m < 3">
						{{ detail[m].name }}
						<v-progress-linear
							v-model="detail[m].branches_count"
							height="25"
							striped
						>
							<strong>{{
								Math.ceil(parseInt(detail[m].branches_count))
							}}</strong>
						</v-progress-linear>
					</div>
				</v-col>
			</v-row>
		</v-card>
		<v-card class="px-8 pb-8 pt-2 mt-n2">
			<v-row>
				<v-col cols="6">
					<v-card class="px-5 py-0" flat>
						<v-card-title class="display-2 font-weight-light mb-4">
							<v-label>
								<h1 style="color:grey">Orders Per service</h1>
								<span v-if="filterRangeText" v-html="filterRangeText"></span>
							</v-label>
						</v-card-title>

						<v-card-text>
							<apexchart
								type="bar"
								:options="chartOptions"
								height="250"
								:series="series2"
							></apexchart>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="6">
					<v-card class="px-5 py-0" flat>
						<v-card-title class="display-2 font-weight-light mb-4">
							<v-label>
								<h1 style="color:grey">Orders Per Branch</h1>
								<span v-if="filterRangeText" v-html="filterRangeText"></span>
							</v-label>
						</v-card-title>

						<v-card-text>
							<apexchart
								type="bar"
								height="250"
								:options="chartOptions2"
								:series="series1"
							></apexchart>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col cols="12">
					<v-card class="px-5 py-0" flat>
						<v-card-title class="display-2 font-weight-light mb-4">
							<v-label>
								<h1 style="color:grey">Total Sales/Costs</h1>
								<span v-if="filterRangeText" v-html="filterRangeText"></span>
							</v-label>
						</v-card-title>

						<v-card-text>
							<apexchart
								type="bar"
								height="270"
								:options="total_profit_chart.chartOptions"
								:series="total_profit_chart.series"
							>
							</apexchart>
						</v-card-text>
					</v-card>
				</v-col>
				<!--   <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >

                      <div class="">
                      <center><h3>Orders: 71 </h3> </center>
                   <apexchart
                    min-width="500" type="bar"
                     :options="options" :series="series">
                   </apexchart>
                  </div>
                  </v-col> -->
				<v-col cols="12" md="6"></v-col>
			</v-row>
			<!-- <v-row v-else>
               <v-col
                       cols="12"

               >
               <base-material-card
                       icon="mdi-archive"
                       title="My Invoices"
                       class="px-5 py-3"
                       color="primary"
               >
                 <v-alert
                         border="left"
                         color="red"
                         class="lighten-2"
                         dark
                 >
                   Permission Denied
                 </v-alert>
               </base-material-card>
               </v-col>
             </v-row> -->
		</v-card>
	</v-container>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
export default {
	name: "DashboardDashboard",

	data() {
		return {
			total_profit: 2000,
			branches: ["Dammam", "Riyadh", "Jeddah"],
			dstate: true,
			dateRangeMenue: 0,
			dates: [],
			customFilterVisible: false,
			total_sales: 0,
			filterFromDialog: false,
			filterToDialog: false,
			filterTo: "",
			filterFrom: "",
			Available: 0,
			spent: [],
			detail: [],
			sales_data: null,
			topServ: [],
			skill: 20,
			knowledge: 33,
			power: 78,
			skil: 5000,
			knowled: 33,
			pow: 78,
			show: false,
			interval: {},
			value: 0,
			radioGroup: 0,
			date: null,
			from: null,
			to: null,
			dateFilterState: false,
			modal: false,
			user_id: localStorage.getItem("userid"),
			token: localStorage.getItem("token"),
			month: moment().format("MMMM YYYY"),
			now: moment().format("MMMM Do YYYY"),
			counters: [],
			all_orders: 0,
			ongoing_orders: 0,
			completed_orders: 0,
			total_spent: 0,
			series1: [],
			series2: [],

			//ApexCharts

			total_profit_chart: [
				{
					name: "In Progress",
					data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
				},
				{
					name: "Complete Orders",
					data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
				},
				{
					name: "Pending Orders",
					data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
				}
			],
			total_profit_chartOptions: {
				chart: {
					type: "bar",
					toolbar: {
						show: false
					}
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: "30%",
						endingShape: "rounded"
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					show: false,
					width: 1,
					colors: ["transparent"]
				},
				xaxis: {
					categories: [
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct"
					]
				},
				yaxis: {
					title: {
						text: ""
					}
				},
				fill: {
					opacity: 1
				},
				tooltip: {
					y: {
						formatter: function(val) {
							return "$ " + val + " thousands";
						}
					}
				},

				legend: {
					markers: {
						radius: 50
					}
				}
			},
			my_wallet_chart: {
				series: [0, 80, 20],

				chartOptions: {
					chart: {
						type: "donut",
						toolbar: false
					},
					colors: ["#7d8d99", "#1e66c3", "#279c4b"],
					responsive: [
						{
							// breakpoint: 480,
							// options: {
							// 	chart: {}
							// }
						}
					],
					legend: {
						position: "bottom",
						offsetY: 10,
						height: 70,
						itemMargin: {
							horizontal: 20
						}
					},
					labels: ["Salse", "Spent", "Profit"],
					plotOptions: {
						pie: {
							dataLabels: {
								offset: 0,
								minAngleToShowLabel: 10
							},
							donut: {
								size: "85%"
							}
						}
					}
				},
				markers: {
					offsetX: 50
				}
			}
		};
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	computed: {
		format_total_profit() {
			let tp = this.numberWithCommas(this.total_profit);

			return `${tp} SR`;
		},
		getDateRange() {
			return (this.dstate = !this.dstate);
		},
		dateRangeText() {
			return this.dates.join(" to ");
		},
		filterRangeText() {
			if (this.filterFrom && this.filterTo) {
				return `<h3 class="mt-2">(<small>From: ${this.filterFrom}, To: ${this.filterTo}</small>)</h3>`;
			}
			return null;
		}
	},
	watch: {
		ordersbranch: {
			handler() {
				this.chartOptions2 = {
					xaxis: { categories: this.ordersbranch.Category }
				};
				this.series1 = [{ data: this.ordersbranch.series.orders }];
			},
			deep: true
		},
		filterFrom: {
			handler() {
				this.filterDateChange();
			},
			deep: true
		},
		filterTo: {
			handler() {
				this.filterDateChange();
			},
			deep: true
		},

		ordersCategory: {
			handler() {
				this.chartOptions = {
					xaxis: { categories: this.ordersCategory.Category }
				};
				this.series2 = [{ data: this.ordersCategory.series.orders }];
				// this.series = this.ordersdata.series.orders;
				// this.series2 = this.ordersdata.series.orders_sales;
			},
			deep: true
		},
		sales_data: {
			handler() {
				// this.chartOptions = {  xaxis: {categories: this.ordersCategory.Category}};
				this.series3 = [
					// { name: "Total sales", data: [0, 0, 13800] }, //this.sales_data.sales_cost_per_month.data
					{ name: "Total Costs", data: this.sales_data.cost_per_month.data }, //this.sales_data.sales_cost_per_month.data
					{ name: "Total Sales", data: this.sales_data.sale_per_month.data }
				];
				// this.series = this.ordersdata.series.orders;
				// this.series2 = this.ordersdata.series.orders_sales;
			},
			deep: true
		}
	},
	mounted() {
		// this.getOrderByCategory();
		// this.getOrderByBranch();
		this.getTopServices();
		this.interval = setInterval(() => {
			if (this.value === 100) {
				return (this.value = 0);
			}
			this.value += 10;
		}, 1000);
	},
	methods: {
		numberWithCommas(x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		getOrderByCategory() {
			axios
				.post("/api/v3/partner/dashboardCategory", {
					merchant_id: this.user_id
				})
				.then(res => {
					//this.ordersCategory = res.data;
					//alert(this.ordersCategory)
				})
				.catch(err => console.log(err.response.data));
		},

		filterDateText(payload) {
			if (payload == "last_week") {
				this.filterFrom = moment()
					.subtract(1, "weeks")
					.startOf("isoWeek")
					.format("YYYY-MM-DD");
				this.filterTo = moment().format("YYYY-MM-DD");
			} else if (payload == "last_month") {
				this.filterFrom = moment()
					.subtract(1, "months")
					.format("YYYY-MM-DD");
				this.filterTo = moment().format("YYYY-MM-DD");
			} else if (payload == "custom") {
				this.customFilterVisible = true;
			}
			this.getTopServices();
		},
		getOrderByBranch() {
			axios
				.post("/api/v3/partner/dashboardBranch", {
					merchant_id: this.user_id,
					from: this.from,
					to: this.to
				})
				.then(res => {
					// this.ordersbranch = res.data;
				})
				.catch(err => console.log(err.response.data));
		},
		getTopServices() {
			// axios
			//     .get("/api/v3/b2b/partner-details", {
			//         headers: { "X-Authorization": this.token },
			//         params: {
			//             partner_id: this.user_id,
			//         },
			//     })
			axios
				.get("/api/v3/b2b/dashboard", {
					headers: { "X-Authorization": this.token },
					params: {
						from: this.from,
						to: this.to
					}
				})
				.then(res => {
					this.filterFrom = res.data.from_date;
					this.filterTo = res.data.to_date;
					this.all_orders = res.data.all_orders;
					this.ongoing_orders = res.data.ongoing_orders;
					this.completed_orders = res.data.completed_orders;
					this.total_spent = res.data.partner_detail.credit_spent;
					this.detail = res.data.top_branches;
					this.topServ = res.data.top_services;
					this.total_sales = res.data.total_sales;
					this.sales_data = res.data;
					this.Available = res.data.partner_detail.credit_balance;
					this.spent = res.data.partner_detail;
					this.ordersCategory = res.data.dashboard_category;
					this.ordersbranch = res.data.dashboard_branch;

					console.info(res.data.remaining_credit_limit);

					// alert(this.sales_data)
				})
				.catch(err => console.log(err.response.data));
		},
		filterDateChange(e) {
			this.from = this.filterFrom;
			this.to = this.filterTo;
			this.getTopServices();
		}
	}
};
</script>
<style scoped>
.v-progress-circular {
	margin: 1rem;
}
.lab,
p {
	color: #ffffff !important;
	font-size: 16px;
}
/* Temporary workaound v-card default  */
.profit_card {
	border: 1px solid rgb(0, 212, 46) !important;
	background-image: url(../../../assets/money.png);
	background-position: right;
}
</style>
